import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/reklamacje-questy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "panel-kierownika-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "kanban.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "formularz-tworzenia-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "formularz-tworzenia-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "uzasadniono.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie6: file(relativePath: { eq: "wyszukiwarka-bez-id.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie7: file(relativePath: { eq: "przyczyna-reklamacji.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie8: file(relativePath: { eq: "formularz-klient.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const wdrozeniepanelub2b = ({ data }) => {
  return (
    <Artykul
      title="Obsługa reklamacji online – droga do efektywności"
      articleImage={obrazekArtykulu}
      articleImageAlt="Obsługa reklamacji online – droga do efektywności"
      metaTitle="Obsługa reklamacji online – droga do efektywności"
      metaDescription="Obsługa reklamacji online✅ Jak zorganizować proces krok po kroku? • Wypróbuj bezpłatne demo! • Video 2022"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >
      Prowadzisz przedsiębiorstwo, które na co dzień realizuje zlecenia reklamacyjne? 
      Bywasz zmęczony rozbudowanym i nieustrukturyzowanym procesem zarządzania reklamacjami? 
      Chciałbyś zapewnić komfort swoim klientom, a jednocześnie dbać o&nbsp;efektywną realizację 
      zadań przez pracowników?
    </p>
    <p>
     <strong>Odpowiednie rozwiązanie informatyczne i&nbsp;obsługa reklamacji 
     online mogą pomóc Ci w&nbsp;usprawnieniu Twojego biznesu.</strong>
    </p>
    <p> 
     Jeżeli zastanawiasz się nad programem do reklamacji, warto wiedzieć czym charakteryzuje 
     się to narzędzie oraz w jaki sposób ono funkcjonuje. Znajomość możliwości jakie stwarza 
     obsługa reklamacji online jest pierwszym krokiem do wdrożenia mechanizmu. 
     Mając świadomość o&nbsp;zaletach systemu, można w odpowiedni sposób wykorzystać 
     jego potencjał w prowadzonej działalności.
    </p>
    <p>
     Celem poniższego materiału jest dostarczenie Tobie wiedzy o obsłudze reklamacji online 
     za pomocą odpowiedniego systemu informatycznego. Jeśli jesteś zainteresowany, 
     zapraszamy do obejrzenia filmu lub przeczytania naszego artykułu.
</p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="WKUJ_7FBVtU"
          title="Skuteczna obsługa REKLAMACJI w systemie informatycznym"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Obsługa reklamacji online – podstawy</strong>
      </h2>
      <p>
        Obsługa reklamacji online pozwala na stworzenie dwóch odmiennych paneli. 
        Pierwszym z nich jest <strong>panel pracownika</strong> – z oddzielnymi uprawnieniami 
        dla kierownika i pracowników. Drugi to <strong>panel klienta</strong>, który nie jest 
        jednak niezbędny do funkcjonowania całego mechanizmu. Jest rozwiązaniem opcjonalnym, 
        które może być ułatwieniem dla części obsługiwanych klientów.
        </p>
               <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Panel kierownika w obsłudze reklamacji online"]}
      />
      <p>
       Jeżeli panel klienta nie jest obowiązkowy, to w jaki sposób następuje składanie 
       zgłoszeń w obsłudze reklamacji online?
      </p>
      <p>
       Oprócz możliwości <strong>samodzielnego przedstawienia roszczeń za 
       pomocą panelu klienta</strong>, można zrobić to poprzez kontakt z pracownikiem. 
       Wystarczy dokonanie zgłoszenia poprzez wiadomość e-mail lub kontakt telefoniczny 
       i przekazanie wymaganych informacji.
      </p>
      <p>
       Zadaniem pracownika, który zajmuje się obsługą reklamacji online, 
       jest wprowadzenie otrzymanych danych do systemu i potwierdzenie zgłoszenia.
       </p>
       <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz prowadzić obsługę reklamacji online?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej serwisu do zarządzania reklamacjami online

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i usprawnić proces obsługi zleceń reklamacyjnych
            </Link>
          </div>
          </div>
    </section>
       <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Samodzielne zgłoszenia</strong>
      </h3>
      <p>
       Nie zawsze Twój klient ma możliwość kontaktu z&nbsp;odpowiednim pracownikiem przyjmującym 
       zgłoszenia. Samodzielne <strong>złożenie reklamacji w&nbsp;systemie bywa czasami szybsze 
       i bardziej komfortowe.</strong> W związku z&nbsp;powyższym, warto zapewnić klientom dostęp 
       do takiej możliwości.
      </p>
      <p>
       Najprostszym rozwiązaniem jest <strong>link przekierowujący do systemu obsługi 
       reklamacji online,</strong> w którym będzie istniała możliwość zgłoszenia reklamacji.
      </p>
      <p>
       By zapewnić odpowiedni dostęp do systemu – jego widoczność – link przekierowujący 
       powinien zostać umieszczony na Twojej firmowej stronie internetowej. Jeżeli Twoi klienci 
       odwiedzają Twoją stronę, nie powinni mieć problemu z nawigacją.
      </p>
      <p>
       Obawiasz się, że strona internetowa nie jest wystarczajaca? Możesz skorzystać z kanałów, 
       które wykorzystujesz na co dzień w komunikacji ze swoimi odbiorcami. Jeśli jest to skrzynka 
       pocztowa, możesz rozesłać link przekierowujący do systemu obsługi reklamacji online poprzez wiadomość e-mail.
      </p>
      <p>
       Działaniem, które jeszcze bardziej upraszcza obsługę reklamacji online jest możliwość 
       dokonywania zgłoszeń poprzez odpowiedni formularz. Jest to element, który można 
       zastosować wraz z&nbsp;<strong>wdrożeniem rozwiązania dedykowanego.</strong>
      </p> 
 <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Obsługa reklamacji online – krok po kroku</strong>
      </h2>
<p>
 <strong>Zarządzanie reklamacjami jest procesem i składa się z kilku etapów.</strong> Prowadząc 
 działalność, w której zgłoszeń reklamacyjnych może być sporo, porządek 
 i&nbsp;odpowiednia organizacja są bardzo ważne.
</p>
<p>
 Obsługa reklamacji online może pomóc w usprawnieniu prowadzonych działań, 
 a zastosowanie <strong>widoku kanban</strong> daje możliwość śledzenia wszystkich 
 istniejących zgłoszeń z uwzględnieniem etapów, na których się one znajdują.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Widok kanban w obsłudze reklamacji online"]}
      />
<p>
 Dany system nie ma jednego uniwersalnego widoku kanban. 
 Istnieje możliwość tworzenia indywidualnych widoków, które kierowane są do 
 poszczególnych pracowników i zawierają wyłącznie te zlecenia, którymi zajmuje się dana osoba.
</p>
<p>
Obsługa reklamacji online to nie tylko widok kanban. Jeżeli nie odpowiada Ci taki 
podgląd, <strong>możesz śledzić zgłoszenia za pomocą listy lub tabeli.</strong>
</p>

 <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
                    paddingBottom: 20,

        }}
      ><strong>Reklamacja od zgłoszenia do zamknięcia</strong>
      </h3>
<p>
 Kluczowym elementem w obsłudze reklamacji online jest <strong>formularz zgłoszeniowy.</strong> Jest to
  narzędzie wykorzystywane przez klientów do samodzielnego dokonywania zleceń reklamacyjnych. 
  Wspiera również pracowników, którzy umieszczają w nim informacje uzyskane od podmiotów 
  zgłaszających roszczenia mailowo i telefonicznie.
</p>
<p>
 Zadaniem formularza zgłoszeniowego jest przekazywanie istotnych danych, 
 które mają znaczenie w obsłudze reklamacji online. W związku z&nbsp;tym jego <strong>zawartość 
 powinna być dokładnie przemyślana tak, by wspierała proces, a nie go utrudniała.</strong>
 </p>
<p>
 Tak wygląda przykładowy formularz, którym posługuje się pracownik rejestrujący 
 w systemie zgłoszoną reklamację. Formularz, który wypełnia samodzielnie klient może być 
 mniej rozbudowany i&nbsp;uproszczony.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Formularz dla pracownika - część 1"]}
      />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie4]}
        alts={["Formularz dla pracownika - część 2"]}
      />
<p>
 Po dokonaniu zgłoszenia samodzielnie lub wprowadzeniu go do systemu przez pracownika, 
 trafia ono na <strong>listę nowych zleceń.</strong>
</p>
<p>
 Istnieją dwie możliwości przydzielenia takiego zlecenia reklamacyjnego do realizacji:
</p>
<p><ul>
<li>samodzielny dobór zleceń ze wspólnej kolejki,</li>
<li>ręczne przydzielanie zgłoszeń przez kierownika lub dyspozytora.</li>
</ul></p>
<p>
 Pierwszym zadaniem pracownika, który otrzymał zgłoszenie do realizacji, jest <strong>weryfikacja 
 danych zawartych w formularzu zgłoszeniowym.</strong> Sprawdzeniu podlegają dwa istotne 
 fakty – kompletność zgłoszenia oraz jego zasadność.
</p>
<p>
 Następnym etapem obsługi reklamacji online jest decyzja, co do istoty zgłoszenia. 
 Pracownik musi określić, czy jego zdaniem reklamacja powinna zostać przyjęta, 
 czy też odrzucona.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie5]}
        alts={["Podjęcie decyzji w obsłudze reklamacji online"]}
      />
<p>
 Rodzaj decyzji determinuje dalsze kroki. Rozstrzygnięcie pozytywne i przyjęcie reklamacji 
 powoduje przejście procesu na kolejny etap – <strong>“w&nbsp;realizacji”</strong>. 
 Ta faza obsługi reklamacji online skupia się na kwestiach rozliczeniowych oraz 
 realizacji roszczeń klienta.
</p>
<p>
 Po wykonaniu wszystkich działań z etapu “w&nbsp;realizacji” obsługa reklamacji 
 online może przejść w&nbsp;ostatnią fazę – <strong>“zakończono” lub ”rozliczono”.</strong>
</p>
<p>
 Reklamacje odrzucone automatycznie zostają zakończone bez działań w fazie “w realizacji”.
</p>
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Obsługa reklamacji online – elementy warte wykorzystania</strong>
      </h2>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
                    paddingBottom: 20,

        }}
      ><strong>Szybkie wyszukiwanie zleceń</strong>
      </h3>
<p>
 Obsługa reklamacji online to nie tylko widok kanban. 
 Często bywa tak, że pojawia się potrzeba odnalezienia jednego konkretnego zlecenia, 
 które realizowane jest teraz lub zostało już wcześniej wykonane. 
</p>
<p>
 Z pomocą przychodzi <strong>wyszukiwarka</strong>, która pozwala w szybki sposób 
 odnaleźć poszczególne zgłoszenia reklamacyjne na liście zleceń.
</p>
  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie6]}
        alts={["Wyszukiwarka w obsłudze reklamacji online"]}
      />
<p>
 Wyszukiwanie następuje po:
</p>
<p><ul>
<li>polach standardowych – np. imię, nazwisko,</li>
<li>atrybutach – można dodać je samodzielnie.</li>
</ul></p>
<p>
 Przykładowe wyszukiwanie po wybranym atrybucie przedstawia zdjęcie poniżej.
</p>
  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie7]}
        alts={["Wyszukiwanie w obsłudze reklamacji online"]}
      />   
  <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Protokół reklamacyjny</strong>
      </h3>
<p>
 Obsługa reklamacji online stwarza <strong>możliwość automatycznego tworzenia 
 protokołów</strong> z&nbsp;wykorzystaniem danych, które umieszczane są w&nbsp;karcie zlecenia.
</p>
<p> 
 Szybki sposób tworzenia dokumentu to nie jedyna zaleta. <strong>System umożliwia także 
 wysyłkę protokołu.</strong> Może jej dokonać pracownik, ale możliwa jest również automatyzacja, 
 która spowoduje, że dokumentacja zostanie dostarczona w momencie przejścia z
  jednego etapu procesu zarządzania reklamacjami na kolejny.
</p>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Powiadomienia i szczegółowe informacje</strong>
      </h3>
<p>
 Bieżące informowanie klienta o postępach w&nbsp;zgłoszonym zleceniu jest bardzo ważne. 
 Elementem, który przyczynia się do tego są powiadomienia. W&nbsp;zależności od posiadanych danych, 
 powiadomienia te wysyłane są w&nbsp;wiadomościach SMS lub e-mail.
</p>
<p>
 Każda wysłana wiadomość posiada informacje o&nbsp;zmianie etapu oraz niezbędne szczegóły 
 dotyczące postępu w prowadzonych działaniach.
</p> 
<p>
 Jeśli klient posiada konto w panelu klienta, może sprawdzić wszystkie szczegóły bezpośrednio 
 w&nbsp;systemie obsługi reklamacji online.
</p>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Analizowanie i usprawnianie działań</strong>
      </h3>
<p>
 Celem każdego przedsiębiorstwa jest <strong>ciągłe udoskonalanie 
 działalności.</strong> W usprawnieniach pomaga dokładna analiza dotychczasowych
  przedsięwzięć. Obsługa reklamacji online umożliwia monitorowanie i&nbsp;ocenę realizacji 
  za pomocą tworzonych w systemie raportów.
</p>
<p>
 Jakie elementy można przeanalizować za pomocą takich raportów?
</p>
<p>
 Jednym z nich mogą być <strong>przyczyny składanych reklamacji.</strong> Dokładana analiza 
 danych oraz poszukiwanie powtarzalności mogą przyczynić się do znalezienia aspektów, 
 które wymagają poprawek i&nbsp;mogą w przyszłości zmniejszyć liczbę zgłoszeń.
</p>
<p>
 Na podstawie wcześniejszych działań obsługi reklamacji online, można określić <strong>średni 
 czas realizacji</strong> i&nbsp;odpowiednio przygotować swoich klientów na przewidywany czas oczekiwania.
</p>
<p>
 To tylko dwa przykłady. System pozwala tworzyć wiele innych raportów, 
 które mogą być w różnorodny sposób analizowane.
</p>
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Dostęp dla klienta</strong>
      </h2>
<p>
 Jak już zostało wspomniane wcześniej, klient może mieć dostęp do panelu klienta, 
 w którym może się logować, składać reklamacje i sprawdzać szczegóły realizacji.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie8]}
        alts={["Formularz klienta"]}
      />
<p>
 Dodatkowo istnieje możliwość przeglądania zleceń historycznych, które zostały już zakończone.
</p>
<p>
 Funkcje panelu klienta są rozszerzone w <strong>rozwiązaniach dedykowanych</strong>, w których 
 pojawia się nowa możliwość dokonywania zgłoszeń. Wystarczy wykorzystać dokument zamówienia 
 i&nbsp;poprzez wskazanie odpowiedniego elementu z&nbsp;listy – dokonać jego reklamacji.
</p>
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Obsługa reklamacji online z&nbsp;dodatkami</strong>
      </h2>
   <p>
    Aby zwiększyć efektywność przedsiębiorstwa można łączyć obsługę reklamacji online 
    z innymi systemami. Jedną z możliwości jest wdrożenie systemu CRM.
  </p>
  <p>
  Najlepiej korzystać z rozwiązań oferowanych przez jednego dostawcę. Dlaczego?
</p>
  <p><ul>
  <li>wszystkie dane są zgromadzone w jednym miejscu,</li>
<li>łatwość zarządzania różnorodnymi procesami zachodzącymi w przedsiębiorstwie,</li>
<li>możliwość wglądu w różne procesy prowadzone w&nbsp;firmie – przejrzystość działań i możliwość uzupełniania się.</li>
</ul></p>
<h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Co decyduje o wyborze systemu?</strong>
      </h2>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Elastyczność</strong>
      </h3>
<p>
 Rzeczywistość szybko się zmienia, procesy ulegają modyfikacji, a każda prowadzona 
 działalność posiada swoją własną charakterystykę. Obsługa reklamacji online nie może 
 być standardowa i taka sama dla wszystkich.
</p>
<p>
 Program, który chcesz zastosować w swojej firmie musi wyróżniać się <strong>elastycznością.</strong> 
</p>
<p>
 Jakie elementy świadczą o elastyczności systemu?
</p>
<p>
 Są to, między innymi:
</p>
<p><ul>
<li>
 możliwość stworzenia własnych etapów procesu,
</li>
<li>
 możliwość stworzenia własnych pól w karcie zlecenia,
</li>
<li>
 własne nazewnictwo procesu,
</li>
<li>
 możliwość dowolnej konfiguracji panelu klienta,
</li>
<li>
 zarządzanie powiadomieniami.
</li>
</ul></p>
<p>
 Elastyczność nie odnosi się tylko do procesów reklamacyjnych. <strong>Elastyczność systemu 
 powoduje, że nie musi być on stosowany tylko do zarządzania reklamacjami.</strong> Może 
 on służyć również innym działaniom, które prowadzi lub będzie w&nbsp;przyszłości 
 prowadzić Twoja firma.
</p>
<p>
Elastyczny system do obsługi reklamacji online to dobra inwestycja na przyszłość. 
Jeśli za kilka lat zmienisz sposób swojego działania, albo skierujesz swoją działalność w 
innym kierunku, program do obsługi reklamacji będzie mógł być wykorzystany w&nbsp;innym 
sposób niż pierwotnie planowałeś.
</p>
<p>
W związku z powyższym <strong>warto pamiętać o&nbsp;elastyczności przy wyborze systemu do 
obsługi reklamacji.</strong>
</p>
<h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Skalowalność</strong>
      </h3>
<p>
 Innym decydującym aspektem jest <strong>skalowalność</strong>, czyli możliwość rozwoju, 
 powiększenie i skorzystania z&nbsp;dedykowanego rozwiązania.
</p>
<p>
 Każdy zaczyna od prostych rozwiązań, ale celem jest rozwój. Jeśli chcesz się rozwijać to 
 musisz brać pod uwagę także postęp systemu – wykorzystanie prostych rozwiązań w zaawansowanej 
 działalności mija się z&nbsp;celem. Poszukiwanie nowych rozwiązań jest natomiast 
 czasochłonne i kosztowne.
</p> 
<p>
 Najlepiej już na samym początku <strong>dokonywać wyboru z perspektywą na 
 przyszłość</strong> i wybierać te rozwiązania, które oferują możliwość rozwoju.
</p>
<p>
 Przedstawiliśmy Ci jak wygląda obsługa reklamacji online. Jeśli masz jakiekolwiek pytania 
 lub wątpliwości, to zapraszamy do kontaktu. Chętnie opowiemy Ci więcej o naszych rozwiązaniach, 
 a&nbsp;także przedstawimy możliwości wdrożenia systemu w&nbsp;Twojej firmie.
</p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz prowadzić obsługę reklamacji online?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej serwisu do zarządzania reklamacjami online

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i usprawnić proces obsługi zleceń reklamacyjnych
            </Link>
          </div>
          </div>
    </section>

       
      <p>
        <strong>Polecamy również:</strong>
        </p>
        <p>
        <ul>
          <li>
            <Link to="/system-do-obslugi-zgłoszen-serwisowych/">Jak wygląda system do obsługi zgłoszeń serwisowych?</Link>
          </li>
          <li>
            <Link to="/integracja-crm-z-systemem-handlowym/">
              {" "}
              Integracja CRM z systemem handlowym
            </Link>
          </li>
          <li>
            <Link to="/zalety-elastycznego-crm/">
              {" "}
             Zalety elastycznego systemu CRM
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default wdrozeniepanelub2b;
